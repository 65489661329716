import AppContextProvider from './contexts/appcontext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Main } from "./pages/Main/main";
import { Signup } from './pages/Signup/signup';
import { Signin } from './pages/Signin/signin';
import Services from './pages/Services/services';

function App() {

  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
            <Route path="/signup" element={ <Signup />} />
            <Route path="/signin" element={ <Signin />} />
            <Route path="/services" element={ <Services />} />
            <Route path="/" element={<Main /> } />
            <Route path="*" element={<Main /> } />
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
