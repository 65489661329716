const baseRoutes = {
    users: 'users',
    addresses: 'addresses',
};

export const url = {
    users: {
        me: `${baseRoutes.users}/me`,
    },
    addresses: baseRoutes.addresses
}