import React from 'react';
import './collaborate.css';

export const CollaborateSection = () => {
    return (
        <div className="collaborate-section">
            <h2>Do you want to work with us?</h2>
            <div className="collaborate-card-container">
                <div className="collaborate-card">
                    <img src="./collaborate.png" alt="Work with Us" className="collaborate-image" />
                    <h3>Work with Home Services</h3>
                    <p>Do you want to connect to customers in your area? Make your business the first in the list!</p>
                    <button className="collaborate-button">Apply Here</button>
                </div>
            </div>
        </div>
    );
};
