import React from 'react';
import './business-card.css';
import { FaThumbsUp } from 'react-icons/fa'; // Icon for thumbs-up

export const BusinessCard = ({ business }) => {
  return (
    <div className="business-card" key={business.id}>
      <div className="business-card-image-container">
        <img src={business.image} alt={business.name} className="business-card-image" />
        <div className="business-card-service">{business.service}</div>
      </div>
      <p className="business-card-title">{business.name}</p>
      <div className="business-card-rating">
        <FaThumbsUp className="thumbs-up-icon" />
        <span className="rating-percentage">{business.rating}%</span>
        <span className="review-count">({business.reviews})</span>
      </div>
    </div>
  );
};
