export const useInterceptor = () => {
    const authorizationInterceptor = (axiosInstance) => {
        axiosInstance.interceptors.request.use(
            function (request) {
                const token = retrieveTokenFromCookie();

                if (token) {
                    // Set the Authorization header
                    request.headers['Authorization'] = `Bearer ${token}`;
                }

                console.log("REQUEST in interceptor:", request);
                return request; // Return the modified request object
            },
            function (error) {
                console.error("Error in interceptor:", error);
                return Promise.reject(error); // Forward any errors
            }
        );
    };

    const retrieveTokenFromCookie = () => {
        const cookieName = "__session=";
        const cookies = document.cookie.split("; ");
        const sessionCookie = cookies.find(cookie => cookie.startsWith(cookieName));
        
        // Extract the token value
        return sessionCookie ? sessionCookie.split("=")[1] : null;
    };

    return {
        authorizationInterceptor,
    };
};
