import { useApi } from "../../services/api/useApi";
import { url } from "../../services/api/url";

export const useServices = () => {
    const { get } = useApi();
    
    const getUserOverview = async () => {
        return await get(url.users.me);
    };

    return {
        getUserOverview,
    };
}