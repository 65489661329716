import React, { lazy, Suspense } from 'react';
import { SignedIn, SignedOut } from '@clerk/clerk-react';

const LazyServices = lazy(() => import('../Services/services.jsx'));
const LazyLanding = lazy(() => import('../Landing/landing.jsx'));

export const Main = () => {
  return (
    <>
      <SignedIn>
        <Suspense>
          <LazyServices />
        </Suspense>
      </SignedIn>
      <SignedOut>
        <Suspense>
          <LazyLanding />
        </Suspense>
      </SignedOut>
    </>
  );
};
