import { useApi } from "../../../services/api/useApi";
import { url } from "../../../services/api/url";

export const useAddressModal = () => {
    const { post } = useApi();

    const postNewAddress = async (newAddress) => {
        await post(url.addresses, newAddress);
    }

    return {
        postNewAddress,
    };
}