import React, { useState } from 'react';
import './input.css';
import { FaSearch } from 'react-icons/fa'; // FontAwesome search icon

export const SearchInput = ({ placeholder, ariaLabel, handleSubmit }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const clearInput = () => {
    setInputValue('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(inputValue); // Execute the passed handleSubmit function
    }
  };

  return (
    <div className="input-container text-center">
      <div className="input-group mt-3">
        {/* Search icon placed in the input */}

        <span className="input-group-text-span">
          <FaSearch />
        </span>

        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          aria-label={ariaLabel}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />

        {inputValue && (
          <span className="clear-icon" onClick={clearInput}>&times;</span>
        )}
      </div>
    </div>
  );
};
