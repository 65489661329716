import React from "react";
import '../../services.css';

// Dummy data for expert service types (always shown)
const experts = [
    { id: 1, name: "Mow the lawn", image: "/mowLawnIcon.jpg" },
    { id: 2, name: "Clean windows", image: "/cleanWindowIcon.jpg" },
    { id: 3, name: "Plumbing", image: "/plumbingIcon.jpg" },
    { id: 4, name: "House keeping", image: "/houseKeepingIcon.jpg" },
    { id: 5, name: "Electricity", image: "/electricianIcon.jpg" },
    { id: 6, name: "Private events", image: "/privateEventsIcon.jpg" }
  ];
  
export const ExpertServiceTypes = () => {
    return(
        <div className="types-card-container">
          {experts.map((expert) => (
            <div className="types-icon-card" key={expert.id}>
              <img src={expert.image} alt={expert.name} className="types-card-image" />
              <p className="types-card-title">{expert.name}</p>
            </div>
          ))}
        </div>
    );
}