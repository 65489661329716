import React from 'react';
import './footer.css';

export const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-company">
        <h2>home services</h2>
      </div>

      <div className="footer-content">
        <div className="footer-collaborate">
          <h3>Collaborate with Us</h3>
          <p>Work with us</p>
        </div>

        <div className="footer-links">
          <h3>Interesting Links</h3>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#faq">FAQ</a></li>
            <li><a href="#contact">Contact</a></li>
            <li><a href="#legal">Legal</a></li>
          </ul>
        </div>

        <div className="footer-social">
          <h3>Follow Us</h3>
          <ul>
            <li><a href="#facebook">Facebook</a></li>
            <li><a href="#twitter">Twitter</a></li>
            <li><a href="#instagram">Instagram</a></li>
            <li><a href="#linkedin">LinkedIn</a></li>
          </ul>
        </div>

        <div className="footer-apps">
          <h3>Get the App</h3>
          <div className="app-icons">
            <img src="/app-store-icon.png" alt="App Store" className="app-icon" />
            <img src="/google-play-icon.png" alt="Google Play" className="app-icon" />
          </div>
        </div>

        <div className="footer-policies">
          <h3>Policies</h3>
          <ul>
            <li><a href="#terms">Use Conditions</a></li>
            <li><a href="#privacy">Privacy Policy</a></li>
            <li><a href="#cookies">Cookies Policy</a></li>
          </ul>
        </div>
        
      </div>
    </footer>
  );
};
