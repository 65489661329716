import React, { useState, useEffect } from 'react';
import { Navigation } from '../../components/Navigation/navigation';
import { Footer } from '../../components/Footer/footer';
import './services.css';
import { ExpertServiceTypes } from './partials/expert-service-types/expert-service-types';
import { ExpertsNear } from './partials/experts-near/experts-near';
import { PreviousOrders } from './partials/previous-orders/previous-orders';
import { SearchInput } from '../../components/Input/input';
import { CollaborateSection } from './partials/collaborate/collaborate';
import { useServices } from './useServices';

// Simulated logged-in state and address
const hasPreviousOrders = true;
const userAddress = '1234 Elm St';

const userAddresses = [
  '1234 Elm St, Springfield, IL',
  '5678 Oak Ave, Chicago, IL',
  '9101 Maple Dr, Naperville, IL'
];

const user = {
  addresses: userAddresses,
  selectedAddress: userAddresses[0],
  name: 'Chindasvinto'
}

const Services = () => {
  const [user, setUser] = useState({});
  const { getUserOverview } = useServices();

  useEffect(() => {
    const fetchUserOverview = async () => {
      const userOverview = getUserOverview();
      if (userOverview !== undefined){
        setUser(userOverview);
      }
    }
    fetchUserOverview();
  }, []);

  const handleSearch = (inputValue) => {
    console.log("SEARCH VALUE:", inputValue);
  };

  return (
    <div>
      <Navigation 
        user = {user}
      />
      <div>
        <ExpertServiceTypes />
        <SearchInput 
          placeholder="What do you need today?"
          ariaLabel="Search Services"
          handleSubmit={handleSearch}
        />
        {
        hasPreviousOrders ? (
            <div>
              <PreviousOrders />
              <ExpertsNear />
            </div>
          ) : (
            <ExpertsNear />
          )
        }

      </div>
      <CollaborateSection />
      <Footer />
    </div>
  );
};

export default Services;
