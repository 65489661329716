import React, { useState } from 'react';

export const AddressAutocomplete = ({ onSelectAddress }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isValidSelection, setIsValidSelection] = useState(false);

    const handleInputChange = async (e) => {
        const input = e.target.value;
        setQuery(input);
        setIsValidSelection(false);

        if (input.length > 3) {
            const apiKey = "NmepQNDX7J_MtXyCLyhwqNu_0nRMH97NvQ3o3Mqw5xc";
            const endpoint = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${encodeURIComponent(
                input
            )}&apiKey=${apiKey}&limit=5`;

            try {
                const response = await fetch(endpoint);
                const data = await response.json();

                setSuggestions(
                    data.items.map((item) => ({
                        display_name: item.title,
                        address: item.address,
                        position: item.position,
                    }))
                );
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setSuggestions([]);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion.display_name);
        setSuggestions([]);
        setIsValidSelection(true);
        if (onSelectAddress) {
            onSelectAddress(suggestion);
        }
    };

    const handleBlur = () => {
        if (!isValidSelection) {
            alert("Please select a valid address from the suggestions or use the map to set your location.");
            setQuery('');
        }
    };

    return (
        <div style={{ position: 'relative', maxWidth: '400px', margin: 'auto' }}>
            <input
                type="text"
                value={query}
                onChange={handleInputChange}
                onBlur={handleBlur}
                placeholder="Enter an address"
                style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                }}
            />
            {suggestions.length > 0 && (
                <ul
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        zIndex: 1000,
                        listStyle: 'none',
                        margin: 0,
                        padding: '10px 0',
                    }}
                >
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={{
                                padding: '10px',
                                cursor: 'pointer',
                                borderBottom: '1px solid #f0f0f0',
                            }}
                        >
                            {suggestion.display_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
