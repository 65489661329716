import React from 'react';
import { Navigation } from '../../components/Navigation/navigation';
import { SignUp } from '@clerk/clerk-react';
import './signup.css';

const appearance = {
    layout: {
        socialButtonsPlacement: 'bottom',
        socialButtonsVariant: 'iconButton',
      },
    variables: {
        fontSize: '2vh'
    }
};

export const Signup = () => {
    return(
        <div>
            <Navigation showAddresses={false} showAuthButtons={false}/>
            <div className="sign-up-container">
                <SignUp appearance={appearance} />
            </div>
        </div>
    );
}