import React, { useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { AddAddressModal } from '../AddAddress/add-address-modal';
import { useAddressModal } from './useAddressModal.js';
import './address-modal.css';

export const AddressModal = ({ userAddresses = [], handleClose, handleUserClick }) => {
    const [showAddAddressModal, setShowAddAddressModal] = useState(userAddresses.length > 0 ? false : true);
    const { postNewAddress } = useAddressModal();

    const handleAddressSelect = (address) => {
        handleUserClick(address); // Pass the selected address back to the Navigation component
        handleClose(); // Close the modal after selecting the address
    };

    const handleAddNewAddress = () => {
        setShowAddAddressModal(!showAddAddressModal);
    };

    const handleAddAddressModalClosed = () => {
        handleClose();
    };

    const handleAddAddressSave = (newAddress) => {
        postNewAddress(newAddress);
        handleClose();
    };

    return (
        <>
            {showAddAddressModal && <AddAddressModal handleClose={ handleAddAddressModalClosed } handleSave={ handleAddAddressSave }/>}
            {!showAddAddressModal && (
                <div className="address-modal-overlay">
                    <div className="address-modal">
                        <span className="close-icon" onClick={handleClose}>&times;</span>
                        <h2 className="modal-title">Choose an address</h2>
                        <ul className="address-list">
                            {userAddresses.map((address, index) => (
                                <li
                                    key={index}
                                    className="address-item"
                                    onClick={() => handleAddressSelect(address)}
                                >
                                    <FaHome className="house-icon" />
                                    <span className="address-text">{address}</span>
                                </li>
                            ))}
                        </ul>
                        <div className="button-container">
                            <button
                                className="btn btn-secondary"
                                onClick={handleAddNewAddress}
                            >
                                Add new address
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
