import React from 'react';
import { BusinessCard } from '../../../../components/Card/business-card';
import { FaHome } from "react-icons/fa";

// Dummy data for local businesses (specific to user's address)
const localBusinesses = [
    { id: 1, name: "Contoso Lawn Care", service: "Lawn Mowing", image: "/mowLawn.jpg", rating: 95, reviews: 120 },
    { id: 2, name: "Other Window Cleaning", service: "Window Cleaning", image: "/cleanWindow.jpg", rating: 88, reviews: 85 },
    { id: 3, name: "Acme Plumbing Co.", service: "Plumbing", image: "/plumbing.jpg", rating: 92, reviews: 140 },
    { id: 4, name: "Sparkle Housekeeping", service: "Housekeeping", image: "/houseKeeping.jpg", rating: 90, reviews: 200 },
    { id: 5, name: "Bright Sparks Electricians", service: "Electrical Services", image: "/electricity.jpg", rating: 85, reviews: 95 },
    { id: 6, name: "Gourmet Party Planners", service: "Private Events", image: "/privateEvents.jpg", rating: 97, reviews: 50 }
];

export const PreviousOrders = () => {
    return (
        <div className="card-container-wrapper">
            <h2 className="container-title">
                <FaHome className="title-icon" /> Repeat your order
            </h2>
            <div className="card-container">
                {localBusinesses.map((business) => (
                    <BusinessCard key={business.id} business={business} />
                ))}
            </div>
        </div>
    );
};
